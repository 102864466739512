.support-container
{
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}
.support-title
{
    font-size: 1.32vw;  
    font-family: "DisRegular";
    color:white
}
.contact
{
    font-size: 1.32vw; 
    font-weight:500;
    font-family: "Medium";
    color:white
}