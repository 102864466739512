.twoFaContainer
{
    width: 71vw;
    height: 100%;
    background-color: #0F1E2E;
}
.title
{
    font-family: "medium";
    font-size: 22px;
    color: white;
}
.info
{
    font-family: "Regular";
    font-size: 18px;
    color: white;
}
.link
{
    font-family: "Regular";
    font-size: 18px;
    color: white;
}
.slide
{
    text-align: center;
    font-family: "Regular";
    padding: 50px;
    font-size: 18px;
    color: white;
}