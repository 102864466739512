body {
  overflow: hidden;
}

@import "~antd/dist/antd.css";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Balinese&display=swap');

@font-face {
  font-family: "Bold";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Text-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Regular";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Text-Regular.otf") format("truetype");
}

@font-face {
  font-family: "medium";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Text-Medium.otf") format("truetype");
}

@font-face {
  font-family: "DisBold";
  /*Can be any text*/
  src: url("./fonts/SFPRODISPLAYBOLD.OTF") format("truetype");
}

@font-face {
  font-family: "DisThin";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Display-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "DisRegular";
  /*Can be any text*/
  src: url("./fonts/SFPRODISPLAYREGULAR.OTF") format("truetype");
}

@font-face {
  font-family: "Light";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Display-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  /*Can be any text*/
  src: url("./fonts/SFPRODISPLAYMEDIUM.OTF") format("truetype");
}

@font-face {
  font-family: "SemiBold";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Display-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "SBold";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Text-Semibold.otf") format("truetype");
}

@font-face {
  font-family: "ULight";
  /*Can be any text*/
  src: url("./fonts/SF-Pro-Display-Ultralight.otf") format("truetype");
}

.header-text {
  font-family: 'DisRegular';
  font-size: 33.53px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.368815px;
  color: white;
}

.ant-input-affix-wrapper {
  font-size: 0.97vw;
  padding: 0px;
  padding-right: 1.31vw;
}

.ant-input {
  color: white !important;
}

.ant-select-selection-placeholder {
  color: white;
}

.ant-typography {
  color: white;
}

.ant-input::placeholder {
  color: #272727;
}

.ant-menu-item-selected {
  font-weight: 700;
  color: rgb(0, 0, 0) !important;
  border-bottom: none !important;
}

.ant-menu-item:hover {
  color: rgb(0, 0, 0) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #000000;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #000;
}

.ant-table-thead>tr>th {
  background-color: #272727;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}

tr:nth-child(odd) {
  background: #ebebeb;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

tr:nth-child(even) {
  background: white;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.ant-input:focus,
.ant-input:active,
.ant-input:hover {
  background-color: #ffffff0a;
}

.ant-input::placeholder {
  color: white !important;
}

.ant-input-password-icon.anticon {
  color: white;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: white;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: white;
  border-width: thick;
}

.ant-select {
  color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  background: #ffffff0a;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.ant-select-selection-search-input {
  color: white !important;
}

.ant-select-arrow {
  color: white
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
}

.ant-steps-item-title {
  color: white !important;
}

.ant-card-body {
  padding: 0px;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: white;
  border-color: none;
  background: rgba(36, 33, 47, 0.2);
}

.ant-btn {
  border: none !important;
}

.ant-tabs-tab {
  font-size: 18px !important;
}

.signIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background: #0F1E2E;
  height: 100%;
}

.container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  width: 50%;
  height: 100%;
  background: #0C1A28;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: #0F1E2E;
  height: 100%
}

.signupBtn {
  width: 18vw;
  height: 4.76vw;
  line-height: 20.1px;
  background: rgba(36, 33, 47, 0.2);
  box-shadow: 0.39vw 0.31vw 0.78vw rgb(0 0 0 / 25%), inset 0.08vw 0.08vw 0px rgb(255 255 255 / 25%);
  border-radius: 2.34vw;
  font-family: 'Medium';
  font-size: 16.85px;
  color: white;
}

.input-div {
  width: 25vw;
  height: 3.9vw;
  border-radius: 0.78vw;
  font-family: 'Light';
  font-size: 15.72px;
  padding-left: 28px;
  color: white !important;
  background-color: #ffffff0a !important;
}

.backScreen {
  width: 35%;
  height: 100%;
}

.backContent {
  background-color: #0F1E2E;
  width: 65%;
  display: flex;
  justify-content: center;
}

.setBtn {
  width: 37vw;
  height: 6vh;
  box-sizing: border-box;
  background: #ffffff0a;
  border-radius: 11px;
}

.setText {
  font-size: 1.11vw;
  color: white;
  font-family: "DisRegular";
}
.ant-result-title
{
  font-family: "DisBold" !important;
  color: white !important;
  font-size: 20px !important;
}
.ant-result-subtitle
{
  font-size: 20px !important;
  font-family: "DisBold"  !important;
  color: white !important;
}
div[role="alert"] {
  font-size: 0.97vw;
}