.secContainer {
    width: 71vw;
    height: 100%;
    background-color: #0F1E2E;
}

.info {
    font-size: 1.53vw;
    font-family: medium;
    color: white;
}

.info-text {
    font-family: 'Regular';
    font-size: 1.25vw;
    text-align: "center";
}
.dropdown
{
    margin-top:1vh;
    width: 31vw;
    height:5vh;
    border-radius:0.69vw;
    background-color:#ffffff0a;
    font-family: 'DisRegular';
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.answer
{
    height: 5vh;
    padding-left: 2.36vw !important;
    font-family:'Light' ;
    background-color: #ffffff0a;
}
.qNumber
{
    font-size:1vw;
    font-family:medium;
    color: white;
}