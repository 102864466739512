.header {
    width: 100%;
    font-size:1vw;
    height: 11.2vh;
    background-color: #0C1A28;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-container {
    display: flex;
    justify-content: space-between;
    width:86.39vw;
}

.header-link {
    color: white;
    font-size: 16px;
    font-family: "Regular";
    line-height: 30px;
    letter-spacing: -0.556364px;
}