.signContainer {
    display: flex;
    height: 100vh;
    width: 100%;
  }
.first-container
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 2vh; 
}
.first-text
{
  font-family: 'DisRegular';
  font-style: normal;
  font-size: 1.17vw;
  font-weight: "400";
  color: white;
  line-height: 1.4vw;
}

  