.currencyAssets {
    position: relative;
    width: 463px;
    max-width: 463px;
    height: 75vh;
    background-color: linear-gradient(90deg, rgba(28, 29, 34, 0.2) 0%, rgba(19, 49, 108, 0.2) 103.55%);
    box-shadow: 0px 17.0208px 17.0208px rgba(0, 0, 0, 0.25), 0px 48.4241px 100.635px rgba(0, 0, 0, 0.5);
    border-radius: 2.15vw;
    z-index: 0;
    overflow: hidden;
    background-color: rgb(14, 30, 46);
}

.unallocated {
    width: 357.76px;
    height: 112.15px;
    background: linear-gradient(90deg, rgba(28, 29, 34, 0.5) 0%, rgba(19, 49, 108, 0.5) 103.55%);
    box-shadow: 0px 4.48598px 4.48598px rgba(0, 0, 0, 0.25), 0px 12.7626px 26.5234px rgba(0, 0, 0, 0.5);
    border: 2px solid transparent;
    border-left: 0px;
    border-right: 0px;
    border-image: linear-gradient(206deg, #38DEB6 42.71%, rgba(82, 151, 255, 0) 89.8%);
    border-image-slice: 50;
    padding-top: 28px;
    padding-left: 36px;
    margin-bottom: 5%;
}

.card {
    width: 25vw;
    height: 24vh;
    background: linear-gradient(90deg, rgba(28, 29, 34, 0.5) 0%, rgba(19, 49, 108, 0.5) 103.55%);
    box-shadow: 0px 4.48598px 4.48598px rgba(0, 0, 0, 0.25), 0px 12.7626px 26.5234px rgba(0, 0, 0, 0.5);
    border-radius: 0.35em;
    border: 2px solid transparent;
    border-left: 0px;
    border-right: 0px;
    padding: 2.22vh 2.22vw;
    margin-bottom: 5%;
}

.value {
    color: white;
    font-family: "Light";
    font-size: 14.61px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 0.131736px;
}

.invalue {
    font-size: 28.04px;
    font-family: "ULight";
    text-align: center;
    color: white;
    line-height: 26px;
    letter-spacing: 0.141562px;
}

.structure {
    color: white;
    font-family: "DisRegular";
    text-align: center;
    font-size: 0.83vw;
}

.assetsField {
    font-family: "Regular";
    font-size: 0.75vw;
    color: white;
}

.assetsValue {
    font-size: 0.97vw;
    color: white;
    font-family: "Regular";
    text-align: center;
}

.fixed {
    font-family: "DisRegular";
    font-size: 0.93vw;
    color: white;
}

.assetId {
    font-family: "DisRegular";
    font-size: 29.0658px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.319724px;
    color: white;
    margin-top: 10vh;
}

.assetT {
    font-size: 38.34px;
    font-family: "ULight";
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.138257px;
    color: white;
    margin-top: 18px;
}

.total {
    font-family: "DisRegular";
    font-size: 13.14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.0854342px;
    margin-top: 10px;
    color: white;
}

.label {
    font-family: "Regular";
    font-size: 12.52px;
    color: white;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.0814043px;
}
.value {
    font-size: 1vw;
    font-family: "Light";
    color: white;
}
.videoTag {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2.15vw;
    object-fit: cover;
    z-index: -1;
}