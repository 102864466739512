.message-container
{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
}
.message
{
    font-size: 1.88vw;
    font-family: medium;
    color:white;
}
.message-warning
{
    font-size: 1.39vw;
    font-family: medium;
    color:white;
}