.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 29vw;
    background: #0C1A28;
}
.inactive {
    font-size: 1.11vw;
    color: white;
}
.stepsContainer
{
    margin-top:15vh;
    height:51vh;
    width:12vw;
}