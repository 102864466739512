.backBtn {
    width: 100px;
    height: 50px;
    line-height: 20.1px;
    background: rgba(36, 33, 47, 0.2);
    box-shadow: 0.39vw 0.31vw 0.78vw rgb(0 0 0 / 25%), inset 0.08vw 0.08vw 0px rgb(255 255 255 / 25%);
    border-radius: 2.34vw;
    font-family: 'Medium';
    font-size: 16.85px;
    color: white;
  }