.dashContainer
{
    min-height: 88.8vh;
    padding-top: 6.52vh;
    background-color: #0E1E2E;
}

.user-text {
    font-size: 2.32vw;
    font-family: "Bold";
    color: white;
    letter-spacing: -0.01em;

}
.user-assets {
    font-size: 1.53vw;
    font-family: 'Regular';
    color: white;
    letter-spacing: -0.01em;

}

.textId {
    font-size: 1.56vw;
    font-family: "DisBold";
    color: white;
}

.valueId {
    font-size: 3.33vw;
    color: white;
    font-Family: 'DisThin';
}
.valueD
{
    position: absolute;
    top: 40%;
    left: 10%;
    width: 80%;
    color: white;
}
.cardHolder {
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    width:100%;
}
.currencyCard
{
    width:75%;
    padding-top: 4.07vh;
}
.cardHolder::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.cardHolder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #00000000;
    border-radius: 10px;
}

/* Handle */
.cardHolder::-webkit-scrollbar-thumb {
    background: #c8cad4;
    border-radius: 5px;
}

/* Handle on hover */
.cardHolder::-webkit-scrollbar-thumb:hover {
    background: #0423d4;
}
@media all and (width<=600px)
{
    .currencyCard
{
    width:100%;
}
}