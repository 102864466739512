.btcS {
    font-family: "Regular";
    font-size: 24.28px;
    line-height: 28px;
    letter-spacing: -0.521308px;
    color: white;
}
.currency {
    font-family: "Bold";
    font-size: 17.89px;
    color: white;
}
.property {
    font-size: 17.89px;
    font-family: 'Regular';
    color: white;
    line-height: 23px;
    letter-spacing: -0.0996618px;
}
.priceText {
    font-size: 17.89px;
    font-family: 'SBold';
    color: white;
    line-height: 23px;
    text-align: right;
    letter-spacing: -0.0996618px;
}
.graph {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 73px;
    margin-left: -40%;
    font-size: 42.47px;
    font-family: "DisRegular";
    line-height: 51px;
    letter-spacing: 0.467148px;
    color: white;
}
.graph-container
{
    width: 546px;
    height: 506px;
    background:
      linear-gradient(90deg, rgba(28, 29, 34, 0.2) 0%, rgba(19, 49, 108, 0.2) 103.55%);
    box-shadow:
      0px 18.8369px 18.8369px rgba(0, 0, 0, 0.25), 0px 53.5911px 111.373px rgba(0, 0, 0, 0.5);
    border-radius: 2.36vw;
}