.set-container {
  width: 71vw;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#0F1E2E;
}
.reset-container
{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  width: 65%;
  height:100%;
  background-color: #0F1E2E;
}
.entry-text
{
  font-size: 1.53vw;
  font-family: "medium";
  color:white;
}
.info-text
{
  font-size: 1.25vw;
  font-family: "Regular";
  color:white;
  text-align: center;
}
.title
{
  font-size: 1.53vw;
  font-family: 'medium';
  color: white;
}
.info
{
  font-size: 1.25vw;
  font-family: 'Regular';
  color: white;
}
@media screen and (max-width: 480px) 
{ 
.container{
display: flex;
flex-direction: column;
}
.backScreen
{
  width:100%;
  height:20vh;
}
.reset-container
{
  width:100%;
}
}