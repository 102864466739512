.return-container {
  height: 100vh;
  width: 100%;
  background: #0c1a28;
}
.back {
  margin-top: 9.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-text {
  color: white;
  font-family: "Regular";
  font-size: 1.11vw;
}
@media screen and (max-width: 480px) {
  .back {
    display: none;
  }
}
