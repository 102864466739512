.passwordBtn {
  width: 18vw;
  height: 6vh;
  border-radius: 33px;
  background: rgba(36, 33, 47, 0.2);
  box-shadow: 5.6155px 4.4924px 11.231px rgba(0, 0, 0, 0.25), inset 1.1231px 1.1231px 0px rgba(255, 255, 255, 0.25);
  color: white;
  border: none;
}

.signContainer {
  display: flex;
  height: 100vh;
  width: 100%;
}

.first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.first-text {
  font-family: 'DisRegular';
  font-style: normal;
  font-size: 16.85px;
  color: white;
  line-height:20.1px;
}

.forgot-password {
  font-family: 'Light';
  font-style: normal;
  font-size: 14.6px;
  line-height: 17px;
  color: white;
}
.error-container
{
  position:absolute;
  top: 43% ;
  width: 17vw;
  left: 61%;
}
.error-text {
  font-family: "Medium";
  font-size: 14.83px;
  color: white;
}

.PhoneLogo {
  display: none;
}

@media screen and (max-width:768px) {
  .PhoneLogo {
    display: block !important;
  }

  .sidebar {
    display: none;
  }

  .signIn {
    width: 100% !important;
  }

  .header-text {
    font-size: 2em;
  }

  .head .header-text {
    font-size: 2rem;
  }

  .ant-form {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .signupBtn {
    width: 40vw !important;
    height: 8vw !important;
    line-height: 1.4vw;
    background: rgba(36, 33, 47, 0.2);
    box-shadow: 0.39vw 0.31vw 0.78vw rgb(0 0 0 / 25%), inset 0.08vw 0.08vw 0px rgb(255 255 255 / 25%);
    border-radius: 4.34vw !important;
    font-family: 'Medium';
    font-size: 2vw !important;
    color: white;
  }

  .first-text {
    font-size: 2vw !important;
  }

  .forgot-password {
    font-size: 2vw !important;

  }

  .first-container {
    margin-top: 3vh !important;
  }

  .input-div {
    width: 60vw !important;
    height: 10vw !important;
    border-radius: 0.78vw !important;
    font-family: 'Light';
    font-size: 15.72px;
    color: white;
    background-color: #ffffff0a !important;
  }
}